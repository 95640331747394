<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div v-if="homework" class="box-header with-border content">
            <h2 v-if="homework">
              {{ homework.title }} <span v-if="homework && homework.type == 'spelling'" class="tag is-warning">SPELLING</span><span v-else-if="homework && homework.type == 'number'" class="tag is-number">{{ $store.state.user.locale === 'en_US' ? 'MATH' : 'MATHS' }}</span>
            </h2>
            <p class="small">
              {{ dateFromServerDate(homework.available_from) }} - {{ dateFromServerDate(homework.expiry) }}
            </p>
            <p v-if="homework" :class="'tag is-sm is-' + dateInRange(homework.available_from,homework.expiry)">
              {{ assignmentStatus(homework.available_from,homework.expiry) }}
            </p>
            <div class="box-tools pull-right">
              <button type="button" class="btn btn-box-tool" @click="showAdd">
                <i class="fas fa-edit" />
              </button>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <b-table
              v-if="homeworkData && homework"
              :data.sync="homeworkData"
              :paginated="true"
              :per-page="10"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="false"
              default-sort-direction="desc"
              default-sort="score"
            >
              <template slot-scope="props">
                <b-table-column field="name" label="Pupil" item-width="10px">
                  {{ props.row.name }}
                </b-table-column>
                <b-table-column field="games_played" label="Games Played" sortable>
                  {{ props.row.games_played }}<span v-if="homework.number_plays">/</span>{{ homework.number_plays }}
                  <span v-if="props.row.games_played >= homework.number_plays" class="tag is-success">✓</span>
                </b-table-column>
                <b-table-column key="'status' + id" field="status_score" label="Status" sortable>
                  <img :src="'/static/img/' + badgeImage(props.row.status_score_max)" :title="badgeTitle(props.row.status_score_max)">
                  {{ badgeTitle(props.row.status_score_max) }} ({{ props.row.status_score_max }}pts)
                  <br>
                  <small>Current score: {{ props.row.status_score }}pts</small>
                </b-table-column>
                <b-table-column field="avg" label="Correct" sortable>
                  {{ parseInt(props.row.avg*100) }}%
                </b-table-column>
                <b-table-column field="status_score" label="Speed" sortable>
                  {{ props.row.avg_diff > 0 && props.row.status_score > 0 ? (1 / (props.row.status_score / ( Math.min(5, props.row.games_played) * 1000 * props.row.avg_diff))).toFixed(2) : '0' }} s
                </b-table-column>
                <b-table-column field="avg_diff" label="Difficulty" sortable>
                  {{ props.row.avg_diff }}
                  <progress :class="{'progress':true, 'is-success':props.row.avg_diff <= 1.9, 'is-warning':(props.row.avg_diff > 1.9 && props.row.avg_diff <= 2.9), 'is-danger':(props.row.avg_diff > 2.7 && props.row.avg_diff <= 3.5) }" :value="props.row.avg_diff" :max="homework.type == 'number' ? '3' : '4'">
                    {{ props.row.avg_diff }}
                  </progress>
                </b-table-column>
                <!-- <b-table-column field="score" label="Shed Score" sortable>
                      {{ calcScore(props.row.scores).toLocaleString() }}
                  </b-table-column> -->
                <b-table-column field="total_score" label="Total Score" sortable>
                  {{ props.row.total_score.toLocaleString() }}
                </b-table-column>
                <!-- <b-table-column field="earnings" label="Earnings" sortable>
                      🍯 {{ props.row.earnings.toLocaleString() }}
                  </b-table-column> -->
                <b-table-column>
                  <router-link class="button is-link is-pulled-right" :to="'/pupils/' + props.row.username">
                    View Pupil
                  </router-link>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
            <p v-if="homework && homework.type == 'number'">
              <small>N.B. Status is a rank based on the difficulty and number of answers correct in one minute for the most recent five games. <br>(1 / average time) x difficulty x 1000 x 5 games. 3,000 pts = Worker Bee = 6 seconds per correct answer on "Difficult".<br>0pts = Egg, 500pts = Larva (30s/ans), 1,500pts = Drone (10s/ans), 2500pts = Worker Bee (6s/ans), 5000pts = Soldier Bee (3s/ans), 15,000pts = Royal Bee (1s/ans).</small>
            </p>
            <p v-else-if="homework && homework.type == 'spelling'">
              <small>N.B. Status is a rank based on the difficulty and percentage correct for the most recent five games. <br>5 x Extreme (level 4) x 100% = 2,000pts max.<br>0pts = Egg, 200pts = Larva, 500pts = Drone, 1,000pts = Worker Bee, 1,500pts = Soldier Bee, 1,960pts = Royal Bee.</small>
            </p>
            <div v-if="false" class="row">
              <VueChartist :data="data" :options="options" type="Line" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddHomework v-if="showAddModal" :homework-obj="homework" @close="hideAdd" @update="update" />
  </section>
</template>

<script>
import moment from 'moment'
import AddHomework from '@/components/views/components/AddHomework'
import chartist from 'chartist'
import VueChartist from 'v-chartist'
import { request } from '@/edshed-common/api'

export default {
  name: 'HomeworkDetail',
  components: {
    AddHomework,
    VueChartist,
    chartist
  },
  data (router) {
    return {
      homework: null,
      homeworkData: null,
      showAddModal: false,
      data: {
        labels: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        series: [
          {
            name: 'My nice apples',
            data: [5, 2, 4, 2, 0]
          },
          {
            name: 'My nice lemons',
            data: [8, 1, 14, 12, 10]
          }
        ]
      },
      options: {
        fullWidth: true,
        chartPadding: {
          right: 40
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getHomework()
    })
  },
  methods: {
    badgeImage (score) {
      if (this.homework.type === 'number') {
        if (parseInt(score) >= 15000) { // 1s
          return 'badgeRoyal.png'
        } else if (parseInt(score) >= 5000) { // 3s
          return 'badgeSoldier.png'
        } else if (parseInt(score) >= 2500) { // 6s
          return 'badgeWorker.png'
        } else if (parseInt(score) >= 1500) { // 10s
          return 'badgeDrone.png'
        } else if (parseInt(score) >= 500) { // 30s
          return 'badgeLarve.png'
        } else {
          return 'badgeEgg.png'
        }
      } else if (this.homework.type === 'spelling') {
        if (parseInt(score) >= 1960) {
          return 'badgeRoyal.png'
        } else if (parseInt(score) >= 1500) {
          return 'badgeSoldier.png'
        } else if (parseInt(score) >= 1000) {
          return 'badgeWorker.png'
        } else if (parseInt(score) >= 500) {
          return 'badgeDrone.png'
        } else if (parseInt(score) >= 200) {
          return 'badgeLarve.png'
        } else {
          return 'badgeEgg.png'
        }
      }
    },
    badgeTitle (score) {
      if (this.homework.type === 'number') {
        if (parseInt(score) >= 15000) {
          return 'Royal Bee'
        } else if (parseInt(score) >= 5000) {
          return 'Soldier Bee'
        } else if (parseInt(score) >= 2500) {
          return 'Worker Bee'
        } else if (parseInt(score) >= 1500) {
          return 'Drone'
        } else if (parseInt(score) >= 500) {
          return 'Larva'
        } else {
          return 'Egg'
        }
      } else if (this.homework.type === 'spelling') {
        if (parseInt(score) >= 1960) {
          return 'Royal Bee'
        } else if (parseInt(score) >= 1500) {
          return 'Soldier Bee'
        } else if (parseInt(score) >= 1000) {
          return 'Worker Bee'
        } else if (parseInt(score) >= 500) {
          return 'Drone'
        } else if (parseInt(score) >= 200) {
          return 'Larva'
        } else {
          return 'Egg'
        }
      }
    },
    getHomework () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      request('GET', 'homework/' + this.$route.params.id, null)
        .then((response) => {
          const data = response.data
          this.homework = data.homework

          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
          this.getHomeworkData()
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    getHomeworkData () {
      this.toggleLoading()
      this.$store.commit('TOGGLE_LOADING')
      let ep = '/data'
      if (this.homework && this.homework.type === 'number') {
        ep = '/numberdata'
      }
      request('GET', 'homework/' + this.$route.params.id + ep, null)
        .then((response) => {
          const data = response.data
          this.homeworkData = data.homework
          for (let i = 0; i < this.homeworkData.length; i++) {
            this.homeworkData[i].games_played = parseInt(this.homeworkData[i].games_played)
            this.homeworkData[i].earnings = parseInt(this.homeworkData[i].earnings) || 0
            this.homeworkData[i].avg = parseFloat(this.homeworkData[i].avg)
            this.homeworkData[i].score = this.calcScore(this.homeworkData[i].scores)
            this.homeworkData[i].total_score = (this.homeworkData[i].scores || '').split(',').reduce((a, b) => parseInt(a) + parseInt(b), 0) || 0
          }

          // console.log('readerData: ' + this.readerData)
          this.toggleLoading()
          this.$store.commit('TOGGLE_LOADING')
        })
        .catch((error) => {
          console.log(error)
          this.$store.commit('TOGGLE_LOADING')
          this.toggleLoading()
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    showAdd () {
      this.showAddModal = true
    },
    hideAdd () {
      this.showAddModal = false
      this.getHomeworkData()
      this.getHomework()
    },
    update () {
      this.getHomeworkData()
      this.getHomework()
    },
    toggleLoading () {
      this.loading = (this.loading === '') ? 'loading' : ''
    },
    dateFromServerDate (dt) {
      if (dt === null || dt === '' || !moment(dt).isValid()) {
        return null
      }
      const m = moment(dt)
      // var utcOffset = new Date().getTimezoneOffset()
      // m.add({minutes: utcOffset})
      const dts = m.toDate().toLocaleDateString() + ' ' + m.format('HH:mm')
      return dts // m.format('DD/MM/YYYY HH:mm')
    },
    assignmentStatus (start, end) {
      let startDate = null
      if (start) {
        startDate = moment(start)
      }
      const now = moment()

      let expiry = null
      if (end) {
        expiry = moment(end)
      }
      if (start && startDate > now) {
        return 'pending'
      } else if (end && expiry < now) {
        return 'expired'
      } else {
        return 'active'
      }
    },
    dateInRange (start, end) {
      let startDate = null
      if (start) {
        startDate = moment(start)
      }
      const now = moment()

      let expiry = null
      if (end) {
        expiry = moment(end)
      }
      if (start && startDate > now) {
        return 'default'
      } else if (end && expiry < now) {
        return 'danger'
      } else {
        return 'success'
      }
    },
    calcScore (scores) {
      let score = 0
      if (!scores) {
        return score
      }
      let scoresArr = scores.split(',').reverse()
      scoresArr = scoresArr.filter(function (e) { return e }) // remove empty elements
      for (let i = 0; i < 5; i++) {
        if (scoresArr.length > i) {
          score += parseInt(scoresArr[i])
        }
      }
      return score / 5
    }
  }
}
</script>
